@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
 font-family: "Metropol";
 src: url("./Fontes/Metropol.ttf") format("truetype");
 font-style: normal;
 font-weight: 100;
}

.App {
 font-family: "Metropol", sans-serif;
 overflow-x: hidden;
 position: relative;
 background-color: #f2f2f2;
}

::-webkit-scrollbar {
 width: 8px;
}

::-webkit-scrollbar-track {
 background: #f2f2f2;
 border-radius: 50px;
}

::-webkit-scrollbar-thumb {
 background: #261914;
 border-radius: 50px;
}

*{
  scroll-behavior: smooth;
}

p {
 font-family: "Montserrat", sans-serif;
 font-weight: 300;
}

h1 {
 letter-spacing: 1px;
 font-weight: 100;
}

h2 {
 font-size: 20px;
 letter-spacing: 1px;
 font-weight: 100;
}

h3 {
 font-size: 20px;
 letter-spacing: 1px;
 font-weight: 100;
}

.overlay {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.5);
 backdrop-filter: blur(5px);
 z-index: 1000;
 transition: backdrop-filter 0.3s ease;
}

.header {
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 0 42px;
 width: 100%;
 box-sizing: border-box;
 color: #d9c9c5;
 position: absolute;
 top: 0;
 z-index: 10;
}

.menu-icon {
 font-size: 30px;
 cursor: pointer;
 padding: 10px;
 border-radius: 5px;
 transition: background-color 0.3s;
 color: #d9c9c5;
}

.menu-icon:hover {
 background-color: rgba(217, 201, 197, 0.2);
}

.logo {
 max-width: 300px;
 /* flex-grow: 1; */
 text-align: center;
 justify-content: center;
 align-items: center;
 display: flex;
}
.contact-button button {
 background-color: transparent;
 font-family: "Metropol", sans-serif;
 color: #f2f2f2;
 padding: 2px 20px 5px 20px;
 font-size: 16px;
 cursor: pointer;
 transition: background-color 0.3s;
 border: 1px solid #f2f2f2;
 border-radius: 50px;
 width: 150px;
 font-size: 20px;
}

.contact-button button:hover {
 background-color: #c8b2ad;
 border: 1px solid #c8b2ad;
}

.sidebar {
 position: absolute;
 top: 0;
 left: 0;
 width: 250px;
 height: 100vh;
 background-color: #261914;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: flex-start;
 padding-left: 20px;
 transition: transform 0.3s ease;
 transform: translateX(-100%);
 z-index: 1500;
}

.sidebar.open {
 transform: translateX(0);
}

.sidebar a {
 color: #d9c9c5;
 text-decoration: none;
 font-size: 20px;
 margin: 10px 0;
 transition: color 0.3s;
}

.sidebar a:hover {
 color: #f2f2f2;
}

.slide {
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-size: cover;
 background-position: center;
 opacity: 0;
 transition: opacity 1s ease-in-out;
 z-index: 0;
 display: flex;
 align-items: center;
}

.slide.active {
 opacity: 1;
 z-index: 1;
}

.slide-content {
 position: relative;
 z-index: 10;
 text-align: left;
 color: #f2f2f2;
 padding-left: 50px;
 padding-right: 40%;
 padding-top: 5%;
 line-height: 0.9;
}

.slide-content h2 {
 font-size: 80px;
 margin: 0;
}

.slide-controls {
 position: absolute;
 bottom: 20px;
 left: 50%;
 transform: translateX(-50%);
 display: flex;
 gap: 10px;
 z-index: 100;
}

.dot {
 width: 10px;
 height: 10px;
 background-color: rgba(255, 255, 255, 0.5);
 border-radius: 50%;
 cursor: pointer;
 transition: background-color 0.3s;
}

.dot.active {
 background-color: rgba(255, 255, 255, 1);
 width: 15px;
 height: 15px;
 margin-top: -3px;
}

.section {
 height: 80vh;
 min-height: 500px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 color: #261914;
 padding: 50px 50px;
 background-color: #f2f2f2;
}

.section1 {
 background-size: cover;
 background-position: center;
 height: 100vh;
 display: flex;
 flex-direction: column;
 justify-content: center;
 color: #261914;
 text-align: left;
 box-sizing: border-box;
 position: relative;
}

.section1 .segundo {
 display: none;
}

.section2 {
 background-color: #f2f2f2;
 overflow: hidden;
 height: auto;
 min-height: 70vh;
}

.slider-container {
 position: relative;
 width: 100%;
 overflow: hidden;
}

.slide-item {
 position: relative;
 display: inline-grid !important;
 align-items: center;
 justify-content: center;
 overflow: hidden;
 padding: 5px;
 cursor: pointer;
}

.slide-item h2 {
 margin: 0;
 margin-top: 15px;
 text-align: center;
}

.slide-item img {
 width: auto;
 height: 250px;
 transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
 position: relative;
 opacity: 0.7;
 z-index: 2;
}

.circle-overlay {
 position: absolute;
 top: 50%;
 left: 50%;
 width: 200px;
 height: 200px;
 background-color: #d9c9c5;
 border-radius: 50%;
 transform: translate(-50%, -50%);
 opacity: 0.5;
 transition: width 0.5s ease-in-out, height 0.5s ease-in-out,
  opacity 0.5s ease-in-out;
 z-index: 1;
}

.section2 .slide-item:hover .circle-overlay {
 width: 150px;
 height: 150px;
 opacity: 1;
}

.section2 .slide-item:hover img {
 opacity: 1;
}

.section2 .slide-item:hover img {
 transform: scale(1.1);
}

.slick-slide {
 display: flex !important;
 align-items: center;
 justify-content: center;
}

.slick-prev,
.slick-next {
 z-index: 1;
}

.slick-prev {
 left: 20px;
}

.slick-next {
 right: 20px;
}

.slick-prev:before,
.slick-next:before {
 font-family: "slick";
 font-size: 24px;
 color: #261914;
 opacity: 1;
}

.section3 {
 background-color: #261914;
 color: #f2f2f2;
 align-items: flex-start;
 height: auto;
}

.section3 .slider-container {
 max-width: 100%;
 margin: 0 auto;
 padding: 20px 0;
}

.section3 .slick-track {
 position: relative;
 top: 0;
 left: 0;
 display: block;
 margin-left: auto;
 margin-right: auto;
 gap: 10px;
 display: flex;
}

.section3 .slide-item {
 border-radius: 8px;
 box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
 display: flex !important;
 flex-direction: column;
 align-items: flex-start;
 justify-content: center;
 width: auto !important;
 padding: 0px 0px;
}
.section3 .image-container {
 background-color: #f2f2f2;
 height: 300px;
 display: flex;
 align-items: center;
 justify-content: center;
 overflow: hidden;
 border-radius: 25px;
 padding: 20px;
}

.section3 .image-container img {
 max-width: 90%;
 width: auto;
 height: auto;
 opacity: 1;
}

.section3 .slide-item:hover img {
 transform: scale(1.1);
}

.section3 h1 {
 margin: 0;
 font-size: 50px;
 letter-spacing: 2px;
}

.section3 h2 {
 font-size: 18px;
 margin-top: 10px;
 color: #f2f2f2;
 font-family: "Montserrat", sans-serif;
 font-weight: 300;
 margin-bottom: 0;
 text-align: left;
}

.section3 p {
 font-size: 12px;
 color: #f2f2f2;
 font-family: "Montserrat", sans-serif;
 font-weight: lighter;
 letter-spacing: 1px;
 margin: 0;
}

.section4 {
 background-color: #f2f2f2;
 justify-content: flex-start;
 min-height: 80vh;
 height: auto;
}

.section4 h1 {
 text-align: center;
 color: #261914;
 font-size: 50px;
}

.section4 .content-wrapper {
 display: flex;
 justify-content: space-between;
 align-items: flex-start;
 gap: 20px;
 margin-top: 30px;
 width: 100%;
}

.section4 .left-column {
 flex: 1 1;
 align-items: flex-start;
 flex-direction: column;
 align-self: center;
 display: flex;
 gap: 10px;
 margin-left: 15px;
}

.section4 .opcoes-numero {
 display: flex;
 align-items: center;
 gap: 15px;
 position: relative;
 height: 100px;
}

.section4 .opcoes-numero h1 {
 font-size: 50px;
 color: #261914;
 margin: 0;
 position: relative;
 z-index: 1;
}

.section4 .opcoes-numero h1::before {
 content: "";
 position: absolute;
 top: 50%;
 left: 50%;
 width: 60px;
 height: 60px;
 background-color: #d9c9c5;
 border-radius: 50%;
 transform: translate(-60%, -65%);
 z-index: -1;
}

.section4 .titulo-e-descricao {
 display: flex;
 flex-direction: column;
 align-self: flex-start;
}

.section4 .titulo-e-descricao h2 {
 font-size: 20px;
 color: #261914;
 /* Cor do título na esquerda */
 margin: 0;
}

.section4 .titulo-e-descricao p {
 color: #261914;
 margin-top: 8px;
}

.section4 .right-column {
 flex: 1;
 display: flex;
 justify-content: center;
}

.section4 .right-column img {
 max-width: 100%;
 height: auto;
 margin-right: -50px;
}

.section5 {
 background-color: #f2f2f2;
 padding: 0px;
 height: 100vh;
}

.section5 .columns-wrapper {
 display: flex;
 justify-content: space-between;
 width: 100%;
}

.section5 .column {
 flex: 1;
 background-color: #d9c9c5;
 transition: flex 0.3s ease, background-color 0.3s ease;
 overflow: hidden;
 cursor: pointer;
 text-align: center;
 height: 100vh;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 position: relative;
 /* Para posicionar o pseudo-elemento corretamente */
 background-size: cover;
 background-position: center;
}

.section5 .column::before {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background: rgba(0, 0, 0, 0.5);
 /* Camada de opacidade preta com 50% de opacidade */
 transition: background 0.3s ease;
 /* Transição suave para a opacidade */
 z-index: 1;
 /* Fica abaixo do conteúdo */
}

.section5 .column:hover::before {
 background: rgba(0, 0, 0, 0);
 /* Remove a opacidade no hover */
}

.section5 .column-1 {
 background-image: url("./Imagens/fdf2a0a7-881d-4df0-b02b-a068c9384529.jpg");
}

.section5 .column-2 {
 background-image: url("./Imagens/5214d947-35af-4d34-b1ec-66e14c961501.jpg");
}

.section5 .column-3 {
 background-image: url("./Imagens/295f0ecf-0945-46b5-be5c-561b1cf485a4.jpg");
}

.section5 .column-4 {
 background-image: url("./Imagens/40868153-1265-4190-98aa-e23539962beb.jpg");
}

.section5 .column-5 {
 background-image: url("../public/Imagens/Secao05_5.jpg");
}

.section5 .column:hover {
 flex: 1.5;
 background-color: #261914;
}

.section5 .column .content {
 display: flex;
 flex-direction: column;
 height: 100%;
 justify-content: space-between;
 position: relative;
 z-index: 2;
}

.section5 .column .top-text {
 background: linear-gradient(to bottom, rgba(38, 25, 30, 0.9), transparent);
 padding: 15px;
 height: 250px;
 color: #f2f2f2;
}

.section5 .column .bottom-text {
 background: linear-gradient(to top, rgba(38, 25, 30, 0.9), transparent);
 padding: 15px;
 height: 150px;
 color: #f2f2f2;
}

.section5 .column .top-text h2 {
 font-size: 50px;
 -webkit-text-stroke: 1px #f2f2f2;
 color: transparent;
}

.section5 .column .bottom-text {
 text-align: left;
}

.section5 .column .bottom-text h2 {
 font-size: 35px;
 margin: 0;
}

.section5 .column .top-text h2,
.section5 .column .bottom-text p {
 margin: 0;
}

.section5 .column .bottom-text h2,
.section5 .column .bottom-text p {
 opacity: 0;
 transform: translateY(0px);
 transition: opacity 0.3s ease, transform 0.3s ease;
}

.section5 .column:hover .bottom-text h2,
.section5 .column:hover .bottom-text p {
 opacity: 1;
 transform: translateY(20px);
}

.section5 .column:hover .top-text h2 {
 opacity: 1;
 color: #f2f2f2 !important;
}

.section6 {
 background-color: #f2f2f2;
 height: auto;
}

.section6 h1 {
 font-size: 50px;
 margin-bottom: 100px;
}

.section6 h2 {
 font-size: 50px;
 letter-spacing: 1px;
 font-weight: 100;
 margin: 0;
 margin-top: 0px;
}

.section6 .projects-page1,
.section6 .projects-page2 {
 margin: 0 auto;
 width: 100%;
}

.section6 .slick-slide {
 display: flex;
 justify-content: center;
 align-items: center;
}

.section6 .project-content {
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 100px;
}

.section6 .project-info {
 background-color: rgba(38, 25, 30, 0.8);
 padding: 25px;
 min-height: 40vh;
 width: 65vh;
 align-content: center;
 border-radius: 25px;
 position: absolute;
 color: #f2f2f2;
}

.section6 .project-image {
 flex: 1;
 text-align: right;
}

.section6 .project-image img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: cover;
}

.section6 .project-content.reverse {
 flex-direction: row-reverse;
 margin-right: 0px;
 margin-left: -150px;
}

.section6 .dots-container {
 border-radius: 10px;
 padding: 10px;
 text-align: center;
 margin: 10px auto;
}

.section6 .slick-dots {
 position: static;
 display: block;
 width: 100%;
 padding: 0;
 margin: 0;
 list-style: none;
 text-align: center;
}

.section6 .dots-list {
 margin: 0;
 padding: 0;
 display: flex;
 justify-content: center;
 gap: 15px;
 list-style-type: none; /* Remove a marcação padrão dos itens da lista */
}

.section6 .dot {
 width: 30px;
 height: 30px;
 color: #f2f2f2;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #d9c9c5;
 font-size: 14px;
 font-weight: bold;
}

.section6 .slick-dots .slick-active .dot {
 width: 40px;
 height: 40px;
 margin-top: -5px;
 margin-left: -5px;
 color: #f2f2f2;
 background-color: #261914;
}

.section6 .popup {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.5);
 display: flex;
 justify-content: center;
 align-items: center;
 z-index: 1000;

}

.section6 .popup-content {
 background: #f2f2f2;
 padding: 20px;
 border-radius: 20px;
 text-align: center;
 max-height: 85%;
 overflow: auto;
 overflow-x: hidden;
 margin: 10%;
 width: 80vw;
}

.section6 .popup-content button {
 justify-content: right;
 float: right;
 margin-left: -60px;
 color: #f2f2f2;
 background-color: #261914;
 border: none;
 padding: 15px;
 cursor: pointer;
 font-size: 16px;
 border-radius: 50%;
 height: 44px;
}

.section6 .popup-content h2{
  font-size: 40px;
  padding-top: 50px;
}

.section6 .gallery {
 display: grid;
 grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
 gap: 10px;
 padding: 10px 0;
}

.section6 .gallery-image img {
  width: 150px; /* Defina a largura que preferir */
  height: 150px; /* Defina a altura que preferir */
  object-fit: cover; /* Faz com que a imagem seja cortada para caber */
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.section6 .gallery-image img:hover {
 transform: scale(1.05);
}

/* Estilo para o contêiner em tela cheia */
.fullscreen-overlay {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.8);
 display: flex;
 justify-content: center;
 align-items: center;
 z-index: 1000;
 opacity: 0;
 visibility: hidden;
 transition: opacity 0.3s ease, visibility 0.3s ease;
}

.fullscreen-overlay img {
 max-width: 90%;
 max-height: 90%;
 border-radius: 8px;
}

/* Mostrar o overlay em tela cheia */
.fullscreen-overlay.active {
 opacity: 1;
 visibility: visible;
}

.fullscreen-overlay .close-button {
 position: absolute;
 top: 20px;
 right: 20px;
 background-color: #f2f2f2;
 border: none;
 padding: 15px;
 cursor: pointer;
 font-size: 16px;
 border-radius: 50%;
 height: 44px;
}

.section7 {
 height: 120vh;
 position: relative;
}

.section7 h1{
  font-size: 50px;
  text-align: center;
}

.section7 .slick-slider {
 width: 100%;
}

  .section7 .room-2 {
    display: flex !important;
    flex-direction: column;
    background-image: url(./Imagens/ambiente2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    box-sizing: border-box;
    border-radius: 50%;
    background-position: bottom;
    height: 600px;
  }
  
  .section7 .room {
    display: flex !important;
    flex-direction: column;
    background-image: url(./Imagens/ambiente1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    box-sizing: border-box;
    border-radius: 50%;
    background-position: bottom;
    height: 600px;
  }
  
  
  .section7 .top-section {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
  }

.section7 .lamp {
 position: absolute !important;
 left: 0;
}

.section7 .lamp .furniture-overlay {
 position: absolute;
 top: 0;
 left: 50%;
}

.section7 .lamp img {
 height: 450px;
 max-width: none;
}

.section7 .tapete img {
 max-width: 400px !important;
}

.section7 .lamp-container {
 position: relative;
}

.section7 .lamp-container img {
 max-width: 100%;
 max-height: 200px;
 z-index: 1;
 position: relative;
}

.section7 .light-effect {
 position: absolute;
 top: 50%;
 left: 50%;
 width: 200%;
 height: 200%;
 background: radial-gradient(
  circle,
  rgba(255, 255, 255, 0.7),
  rgba(255, 255, 255, 0) 80%
 );
 filter: blur(8px);
 transform: translate(-50%, -50%);
 pointer-events: none;
}

.section7 .bottom-section {
 display: flex;
 justify-content: space-evenly;
 align-items: flex-end;
 flex: 1;
 position: relative;
 width: 80vw;
}

.section7 .furniture {
 position: relative;
}

.PopPuff{
  left: 200px !important;
}

.section7 .sofa{
  position: absolute;
  top: 15%; /* Ajuste este valor para mover o tapete verticalmente */
  left: 37%; /* Ajuste este valor para mover o tapete horizontalmente */
  transform: translate(-50%, -50%) rotate(-1deg); /* Centraliza o tapete em relação à sua posição */
  z-index: 1; /* Certifique-se de que ele esteja na camada correta */
}
.section7 .sofa IMG{
  width: 513px;
  max-width: 1000px !important; /* Ajuste o tamanho do tapete conforme necessário */
  height: auto; /* Mantém a proporção do tapete */
}
.section7 .poltrona{
  position: absolute;
  top: 2%; /* Ajuste este valor para mover o tapete verticalmente */
  left: 76%; /* Ajuste este valor para mover o tapete horizontalmente */
  transform: translate(-50%, -50%) rotate(0deg); /* Centraliza o tapete em relação à sua posição */
  z-index: 1; /* Certifique-se de que ele esteja na camada correta */
}
.section7 .poltrona IMG{
  width: 194px;
  max-width: 1000px !important; /* Ajuste o tamanho do tapete conforme necessário */
  height: auto; /* Mantém a proporção do tapete */
}
.section7 .levoo{
  position: absolute;
  top: 41%; /* Ajuste este valor para mover o tapete verticalmente */
  left: 57%; /* Ajuste este valor para mover o tapete horizontalmente */
  transform: translate(-50%, -50%) rotate(0deg); /* Centraliza o tapete em relação à sua posição */
  z-index: 10; /* Certifique-se de que ele esteja na camada correta */
}
.section7 .levoo IMG{
  width: 263px;
  max-width: 1000px !important; /* Ajuste o tamanho do tapete conforme necessário */
  height: auto; /* Mantém a proporção do tapete */
}
.section7 .poltrona2{
  position: absolute;
  top: -3%; /* Ajuste este valor para mover o tapete verticalmente */
  left: 78%; /* Ajuste este valor para mover o tapete horizontalmente */
  transform: translate(-50%, -50%) rotate(0deg); /* Centraliza o tapete em relação à sua posição */
  z-index: 1; /* Certifique-se de que ele esteja na camada correta */
}
.section7 .poltrona2 IMG{
  width: 543px;
  max-width: 1000px !important; /* Ajuste o tamanho do tapete conforme necessário */
  height: auto; /* Mantém a proporção do tapete */
}.section7 .puff{
  position: absolute;
  top: 4%; /* Ajuste este valor para mover o tapete verticalmente */
  left: 16%; /* Ajuste este valor para mover o tapete horizontalmente */
  transform: translate(-50%, -50%) rotate(0deg); /* Centraliza o tapete em relação à sua posição */
  z-index: 1; /* Certifique-se de que ele esteja na camada correta */
}
.section7 .puff IMG{
  width: 667px;
  max-width: 1000px !important; /* Ajuste o tamanho do tapete conforme necessário */
  height: auto; /* Mantém a proporção do tapete */
}


.section7 .furniture img {
 max-width: 300px;
 transition: filter 0.3s ease, transform 0.3s ease;
 z-index: 1;
 -webkit-filter: grayscale(50%);
 filter: grayscale(50%);
}

.section7 .furniture:hover img {
 filter: drop-shadow(4px 2px 2px #f2f2f2) drop-shadow(-4px -4px 2px #f2f2f2)
  grayscale(0%);
 transform: scale(1.05);
 -webkit-filter: drop-shadow(4px 2px 2px #f2f2f2)
  drop-shadow(-4px -4px 2px #f2f2f2) grayscale(0%);
}

.section7 .furniture-overlay {
 position: absolute;
 top: 25%;
 left: 0;
 background-color: #d9c9c5;
 display: flex;
 align-items: center;
 justify-content: flex-start;
 flex-direction: column;
 overflow: hidden;
 transition: max-height 0.6s ease-in-out, width 0.6s ease-in-out,
  opacity 0.6s ease, transform 0.6s ease, border-radius 0.6s ease-in-out;
 max-height: 33px;
 z-index: 2;
 border-radius: 50px;
 opacity: 0.8;
 width: 130px;
 transform: scale(1);
 box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.1),
  6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028);
}

.section7 .furniture-overlay ::-webkit-scrollbar {
 width: 5px;
}

.section7 .furniture-overlay ::-webkit-scrollbar-thumb {
 background: #261914;
 border-radius: 50px;
}

.section7 .furniture-info h2 {
 margin: 0 0 10px 0px;
 text-align: center;
 font-size: 20px;
}

.section7 .furniture-description p {
 opacity: 0;
 transition: opacity 0.6s ease;
 z-index: 2;
 padding: 0 15px 0px 15px;
 overflow: auto;
 max-height: 150px;
 font-size: 14px;
 margin: 0;
}

.section7 .furniture:hover .furniture-overlay {
 opacity: 0.9;
 max-height: 200px; /* Mantém o limite da altura */
 width: 300px;
 border-radius: 25px;
 transform: scale(1.1);
 overflow: visible;
}

.section7 .furniture:hover .furniture-description p {
 opacity: 1;
 transition-delay: 0.6s;
}

.section7 .furniture-info {
 padding: 3%;
}

.section8 {
 background-color: #f2f2f2;
 gap: 70px;
 height: auto;
 padding-bottom: 120px;
}

.section8 .logo-container {
 margin-bottom: 20px;
}

.section8 .content-wrapper {
 display: flex;
 flex-direction: row;
 align-items: center;
 border-radius: 20px;
 background-color: #261914;
 width: 100%;
 justify-content: space-around;
 height: auto;
 padding: 25px 0px;
}

.section8 .image-container {
 display: flex;
 align-items: center;
 position: relative;
 flex: 1;
}

.section8 .overflow-image {
 height: auto;
 max-width: 500px;
 position: absolute;
}

.section8 .coluna-direita-texto {
 display: flex;
 flex-direction: column;
 color: #f2f2f2;
 width: 100%;
 flex: 1;
}

.section8 .coluna-direita-texto .servico {
 display: flex;
 flex-direction: column;
 align-items: flex-start;
}

.section8 .coluna-direita-texto .servico h2 {
 margin: 0;
 font-size: 50px;
}

.section8 .coluna-direita-texto .servico h3 {
 margin-bottom: 10px;
}

.section8 .coluna-direita-texto .servico p {
 margin-top: 0;
 line-height: 1.5;
 margin-bottom: 0;
}

.section8 .contact-button {
 display: flex;
 justify-content: center;
 margin-top: 20px;
}

.section9 {
 min-height: 400px;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: flex-start;
 gap: 50px;
 background-color: #d9c9c5;
 padding: 50px 0;
}

.section9 h1 {
 font-size: 50px;
}

.section9 .container {
 display: flex !important;
 flex-direction: column;
 justify-content: center;
 width: 250px !important;
 height: 250px !important;
 align-items: center;
}

.section9 img {
 -webkit-filter: grayscale(100%);
 filter: grayscale(100%);
}

.section10 {
 background-color: #f2f2f2;
 padding: 0;
}

.section11 {
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 background-color: #f2f2f2;
 padding-bottom: 40px;
 min-height: 500px;
}

.section11 h1 {
 font-size: 50px;
}

.section11 .no-scroll {
 overflow: hidden;
}

.section11 .testimonials-carousel {
 width: 100vw !important;
}

.section11 .testimonial {
 flex-direction: column;
 align-items: center;
 justify-content: center;
 text-align: center;
 padding: 20px;
 width: auto !important;
}

.section11 .testimonial-image {
  display: inline;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
  border: solid 3px;
  background-color: #d9c9c5;
}

.section11 .testimonial-name {
 font-size: 1.5em;
 margin-bottom: 10px;
}

.section11 .testimonial-text {
 font-size: 1.1em;
 color: #555;
}

.section12 {
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 justify-content: flex-start;
 gap: 20px;
 color: #261914;
 padding: 30px;
 margin-bottom: 200px;
 height: 800px !important;
}
.section12 .posts{
  display: flex;
  flex-direction: row;
  height: 600px;
  width: 100%;
}

.section12 .instagram-post{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  height: fit-content;
  padding: 5px;
  width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.section12 .instagram-post:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}


.section12 .imagemInsta{
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 10px;
}
.section12 a{
  text-decoration: none;
}

.section12 .legendaInsta{
  text-decoration: none;
  font-size: 14px;
  color: #333;
  text-align: center;
  margin: 5px 0;
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

}


.section13 {
 justify-content: flex-end;
 background-color: #261914;
 height: auto;
}

.section13 .column-left {
 display: flex;
 flex: 1;
 align-items: center;
}

.section13 .form-container {
 margin-right: 20px;
 display: flex;
 flex-direction: column;
}

.section13 .form-title p {
 font-weight: 100;
 color: #f2f2f2;
}

.section13 .form-fields {
 display: flex;
 gap: 10px;
}

.section13 .input-name,
.section13 .input-email {
 font-family: "Montserrat", sans-serif;
 flex: 1;
 padding: 20px 20px;
 background-color: #f2f2f2;
 border: none;
 border-radius: 50px;
 outline: none;
 transition: border-color 0.3s, background-color 0.3s;
 width: 200px;
}

.section13 .input-name::placeholder,
.section13 .input-email::placeholder {
 color: #261914;
}

.section13 .input-name:focus,
.section13 .input-email:focus {
 border-color: #261914;
 background-color: #ffffff;
}

.section13 .submit-button {
 padding: 12px 20px;
 color: #f2f2f2; /* Cor do texto e do ícone */
 border: #f2f2f2 solid 1px;
 font-family: "Montserrat", sans-serif;
 background-color: #261914;
 cursor: pointer;
 border-radius: 50px;
 width: 100px;
 display: flex;
 align-items: center;
 justify-content: center;
 transition: background-color 0.3s ease, color 0.3s ease; /* Transição suave */
}

.section13 .submit-button svg {
 color: #f2f2f2; /* Cor inicial do ícone */
 transition: color 0.3s ease; /* Transição suave */
}

.section13 .submit-button:hover {
 background-color: #d9c9c5;
 color: #261914; /* Muda a cor do texto e do ícone no hover */
}

.section13 .submit-button:hover svg {
 color: #261914; /* Muda a cor do ícone no hover */
}

.section13 .image-container {
 flex: 1 1;
 display: flex;
 justify-content: center;
 align-items: center;
 width: 400px;
}

.section13 .image-right {
 max-width: 500px;
 height: auto;
 border-radius: 8px;
 position: absolute;
 margin-top: -250px;
 margin-right: -100px;
}

.section13 .column-center {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 margin-top: 150px;
}

.section13 .column-right {
 text-align: center;
}

.section13 .footer-info {
 color: #f2f2f2;
}

.section13 .footer-info p {
 margin: 0;
}

.section13 .logo-container {
 margin: 0px;
}

.section13 .social-icons svg {
 color: #261914;
}

.section13 .social-icons {
 height: 150px;
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 40px;
}

.section13 .social-icons a {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 50px;
 height: 50px;
 background-color: #261914; /* Cor do círculo */
 border-radius: 50%; /* Torna o contêiner circular */
 transition: transform 0.3s ease, background-color 0.3s ease;
 background-color: #d9c9c5;
}

.section13 .social-icons a:hover {
 transform: scale(1.2);
 background-color: #d9c9c5; /* Muda a cor do círculo no hover */
}

.ambiente-container {
 position: relative;
 width: 100%;
 max-width: 1200px;
 margin: 0 auto;
}

.ambiente-image {
 width: 100%;
 border-radius: 8px;
}

.mobilia-item {
 position: absolute;
 cursor: pointer;
}

.descricao-hover {
 display: none;
 position: absolute;
 top: 100%;
 left: 50%;
 transform: translate(-50%, 0);
 background-color: rgba(0, 0, 0, 0.7);
 color: white;
 padding: 10px;
 border-radius: 5px;
 white-space: nowrap;
 z-index: 10;
 text-align: center;
}

.item-image {
 width: 50px; /* Ajuste o tamanho conforme necessário */
 height: auto;
 margin-bottom: 5px;
}

.mobilia-item:hover .descricao-hover {
 display: block;
}

@media (max-width: 768px) {
 h1 {
  margin: 0;
 }
 .section7{
  display: none;
 }

 .section12{
  display: none;
}

.sumir{
  display: none;
}

 .header {
  padding: 0 20px;
 }

 .slide-content {
  position: relative;
  z-index: 10;
  text-align: left;
  color: #f2f2f2;
  padding-left: 20px;
  padding-right: 0%;
  padding-top: 0%;
  line-height: 0.9;
 }

 .slide-content h2 {
  font-size: 50px;
  margin: 0;
 }

 .section {
  padding: 50px 20px;
 }

 .section1 .contact-button {
  display: none;
 }

 .section1 .segundo {
  display: block;
 }

 .section2 {
  min-height: 55vh;
  padding: 0;
 }

 .section3 {
  height: auto;
 }

 .section4 .content-wrapper {
  flex-direction: column-reverse;
 }

 .section4 .opcoes-numero {
  height: auto;
 }

 .section4 .right-column img {
  margin-right: 0;
  margin-left: 10px;
 }

 .section5 {
  padding: 0;
  height: auto;
 }

 .section5 .columns-wrapper {
  flex-direction: column;
 }

 .section5 .column {
  flex: 1;
  height: auto;
  background-color: #d9c9c5;
 }

 .section5 .column .content {
  height: auto;
  justify-content: flex-start;
 }

 .section5 .column .top-text,
 .section5 .column .bottom-text {
  padding: 50px;
 }

 .section5 .column .top-text h2 {
  font-size: 35px;
  -webkit-text-stroke: 0.5px #f2f2f2;
 }

 .section5 .column .bottom-text h2 {
  font-size: 25px;
 }

 .section5 .column .bottom-text p {
  font-size: 16px;
 }

 .section6 h1 {
  font-size: 50px;
  margin-bottom: 50px;
  text-align: center;
 }

 .section6 .slick-prev {
  left: -18px;
 }

 .section6 .slick-next {
  right: -14px;
 }

 .section6 .project-info {
  width: 85%;
  position: relative;
  min-height: auto;
  border-radius: 12px;
  background-color: rgba(38, 25, 30, 1);
  padding: 6%;
 }

 .section6 .reverse .project-info {
  background-color: rgba(38, 25, 30, 1);
 }

 .section6 .project-content {
  margin-bottom: 50px;
  margin-right: 0px;
  height: fit-content;
  flex-direction: column-reverse;
  gap: 15px;
  padding: 0 5px;
 }

 .section6 .project-content.reverse {
  margin-left: 0px;
  flex-direction: column;
 }

 .section6 .project-image img {
  max-width: 100%;
  height: auto;
 }

 .section7 {
  height: auto;
  padding-bottom: 100px;
 }

 .section7 .slick-slide {
  display: block !important;
 }

 .section7 .bottom-section {
  justify-content: space-evenly;
 }

 .section7 .furniture:hover .furniture-overlay {
  width: 150px;
 }

 .section7 .furniture-description p {
  opacity: 0;
  transition: opacity 0.6s ease;
  z-index: 2;
  padding: 0 15px 0px 5px;
  overflow: auto;
  max-height: 75px;
  font-size: 12px;
  margin: 0;
 }

 .section7 .furniture-info {
  padding: 5%;
 }

 .section7 .tapete img {
  max-width: 100px !important;
  position: absolute;
 }

 .section7 .furniture img {
  max-width: 160px;
 }

 .section7 .furniture-overlay {
  top: 5%;
 }

 .section8 {
  gap: 0;
  padding: 50px 20px;
 }

 .section8 .content-wrapper {
  flex-direction: column;
  height: auto;
  width: auto;
  padding: 50px 20px;
 }

 .section8 .image-container {
  width: 100%;
  margin-bottom: 20px;
 }

 .section8 .overflow-image {
  position: relative;
  width: 100%;
 }

 .section8 .coluna-direita-texto {
  width: 100%;
  text-align: center;
 }

 .section8 .coluna-direita-texto .servico h2 {
  font-size: 50px;
  margin-bottom: 20px;
 }

 .section8 .coluna-direita-texto .servico h3 {
  margin-bottom: 20px;
 }

 .section8 .coluna-direita-texto .servico p {
  margin-top: 0;
  line-height: 1.5;
  margin-bottom: 0;
  text-align: left;
 }

 .section8 .coluna-direita-texto .servico {
  display: flex;
  flex-direction: column;
  align-items: normal;
  margin-bottom: 20px;
 }

 .section8 .logo {
  max-width: 100%;
 }

 .section9 {
  min-height: 400px;
  height: auto;
  padding: 20px 0;
  gap: 0;
  display: flex;
  justify-content: center;
  text-align: center;
 }

 .section9 h1 {
  padding: 0 20px;
 }

 .section10 {
  padding: 0;
 }

 .section11 {
  height: auto;
  gap: 50px;
 }

 .section11 .testimonial {
  padding: 0px 50px;
 }

 .section12{
  margin-bottom: 20px;
 }

 .section13 .column-left {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
 }
 .section13 .image-right {
  display: none;
}

 .section13 .form-fields {
  flex-direction: column;
 }

 .section13 .input-name,
 .section13 .input-email {
  width: auto;
 }

 .section13 .submit-button {
  padding: 20px 20px;
  width: auto;
 }

 .section13 .form-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 85%;
 }

 .section13 .column-center {
  margin-top: 90px;
 }
}